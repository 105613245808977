import Head from 'next/head';
import withIntercom from 'src/renderless/withIntercom';
import RouteWithAuth from 'src/RouteWithAuth';
import isAllowedAccess from 'src/screens/fraud-finder/isAllowedAccess';
import { LoginOTL } from 'src/screens/login/login-otl';

const LoginOTLPortalPage = () => {
  return (
    // custom is temporary as it auto redirects to a space with higher auth requirements
    <RouteWithAuth
      redirectOnLoggedIn="/fraud-finder/upload"
      passesCustomAccessCriteria={isAllowedAccess}
    >
      <Head>
        <title>Homeppl Portal</title>
      </Head>
      <LoginOTL successUrl="/login/completed" type="portal" title="Homeppl Portal" />
    </RouteWithAuth>
  );
};

export default withIntercom(LoginOTLPortalPage);
