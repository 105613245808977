import Script from 'next/script';
import React, { useEffect } from 'react';
import useAuth, { MeData } from './useAuth';

const initIntercom = (data: MeData) => {
  // we add the user details to the intercom session if they exist
  (window as any).Intercom &&
    (window as any).Intercom('boot', {
      app_id: process.env.NEXT_PUBLIC_INTERCOM_ID,
      name: data?.first_name ? `${data?.first_name} ${data?.last_name}` : '',
      email: `${data?.email}`,
      user_id: data?.uuid || '',
    });
};

const withIntercom = (Component) => {
  const WrappedComponent = ({ ...props }) => {
    const { user } = useAuth();

    useEffect(() => {
      initIntercom(user.type === 'logged' && user.value);
    }, [user]);

    return (
      <>
        <Script id="intercom">
          {`var APP_ID = "${process.env.NEXT_PUBLIC_INTERCOM_ID}";
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
        </Script>
        <Component {...props} />
      </>
    );
  };

  return WrappedComponent;
};

export default withIntercom;
