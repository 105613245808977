import { useRouter } from 'next/router';
import { Button, FormField, Input } from 'src/components';
import { ArrowRight } from 'src/icons';
import { useForm } from 'src/renderless/useForm';
import { emailFieldRules } from './validation';

export enum FieldEnums {
  email = 'email',
}

const defaultState = {
  [FieldEnums.email]: '',
};

type LoginOtlFormProps = {
  onLogin: (values: Record<FieldEnums, string>) => void;
  backToCrm?: boolean;
  expired?: boolean;
  usernamePasswordUrl?: string;
};
export const LoginOTLForm = ({ onLogin, backToCrm, expired }: LoginOtlFormProps) => {
  const { onSubmit, values, errors, handleBlur, handleChange } = useForm(
    defaultState,
    onLogin,
    emailFieldRules,
  );

  const router = useRouter();

  return (
    <form onSubmit={onSubmit} data-testid="login-form">
      {expired ? (
        <>
          <div className="mb-4 text-lg font-semibold">Login has expired</div>
          <div className="mb-12">
            Due to inactivity we have logged you out of your session, to log back in please use the
            magic link.
          </div>
        </>
      ) : (
        <div className="mb-12">We’ll email you a magic link that'll sign you in instantly!</div>
      )}
      <div>
        <FormField
          labelClassName="!font-medium !text-gray-500 !text-base"
          label="Email address"
          error={errors[FieldEnums.email]}
          data-testid={FieldEnums.email}
        >
          <Input
            value={values.email}
            onChange={(e) => handleChange(FieldEnums.email, e.currentTarget.value)}
            onBlur={handleBlur(FieldEnums.email)}
            name={FieldEnums.email}
            error={errors[FieldEnums.email]}
            autoComplete="email"
          />
        </FormField>
      </div>

      <div>
        <Button type="submit" className="w-full h-10">
          Sign in
        </Button>
      </div>

      {backToCrm && (
        <div className="flex items-center mt-8 ">
          <div className="text-sm">
            <a
              onClick={() => router.push('/crm/login')}
              className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
            >
              Type password instead <ArrowRight className="inline" />
            </a>
          </div>
        </div>
      )}
    </form>
  );
};
