/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import useAuth from 'src/renderless/useAuth';
import { LoginOTLForm } from './otl-form';
import { LoginTemplate } from './template';

export const LoginOTL = ({
  successUrl,
  type,
  title,
}: {
  successUrl: string;
  type: 'crm' | 'portal';
  title: string;
}) => {
  const { loginWithOTL } = useAuth();
  const router = useRouter();
  const expired = 'expired' in router.query;

  const onLogin = async (values: { email: string }) => {
    const { email } = values;
    await loginWithOTL(email, type);
    router.push({ pathname: successUrl, query: { email } });
  };

  return (
    <LoginTemplate title={title} type={type}>
      <LoginOTLForm onLogin={onLogin} expired={expired} backToCrm={type === 'crm'} />
    </LoginTemplate>
  );
};
