/* eslint-disable @next/next/no-img-element */
import { CurrencyPoundIcon, LightningBoltIcon, ClipboardListIcon } from '@heroicons/react/outline';
import SideFormTemplate from 'src/components/templates/SideFormTemplate';

const SideTextGuratantid = {
  title: 'Introducing Homeppl Guarantid',
  subtitle: 'Homeppl will pay the rent if your tenant doesn’t.',
  iconList: [
    {
      icon: LightningBoltIcon,
      description: 'Same day payment',
    },
    {
      icon: ClipboardListIcon,
      description: 'No change to your process',
    },
    {
      icon: CurrencyPoundIcon,
      description: 'From as little as £80 per tenant',
    },
  ],
  link: 'https://www.homeppl.com/business/guarantid/',
};

const SideTextFraudFinder = {
  title: 'Introducing Fraud Finder',
  subtitle: 'Instant document validation and bank transactions extraction',
  iconList: [
    {
      icon: LightningBoltIcon,
      description: 'Instant fraud analysis results',
    },
    {
      icon: ClipboardListIcon,
      description: 'Get bank transactions analysis',
    },
    {
      icon: CurrencyPoundIcon,
      description: 'Save £000s in fraud loss',
    },
  ],
  link: 'https://www.homeppl.com/products/fraud-finder/',
};

export const LoginTemplate = ({
  title,
  subtitle,
  children,
  type = 'crm',
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  type?: 'crm' | 'portal';
}) => {
  const contentText = type === 'crm' ? SideTextGuratantid : SideTextFraudFinder;
  return (
    <SideFormTemplate
      left={{ title, subtitle }}
      right={{
        title: contentText.title,
        subtitle: contentText.subtitle,
        iconList: contentText.iconList,
        below: (
          <p>
            Click{' '}
            <a href={contentText.link} className="text-blue-600 font-semibold">
              here
            </a>{' '}
            to find out more
          </p>
        ),
      }}
    >
      {children}
    </SideFormTemplate>
  );
};
