import { useRouter } from 'next/router';
import React from 'react';
import { Spinner } from 'src/components';
import useAuth, { User } from 'src/renderless/useAuth';

type PropsBase = {
  children: React.ReactNode;
};

type ProtectedRoute = PropsBase & {
  redirectOnLoggedIn?: undefined;
  redirectOnLoggedOut: string;
  passesCustomAccessCriteria?: (user: User) => boolean;
};

type UnprotectedRoute = PropsBase & {
  redirectOnLoggedIn?: string;
  redirectOnLoggedOut?: undefined;
  passesCustomAccessCriteria?: (user: User) => boolean;
};

type Props = ProtectedRoute | UnprotectedRoute;

const FullscreenSpinner = () => (
  <div className="w-full h-screen flex items-center justify-center">
    <Spinner />
  </div>
);

const defaultAccessCriteria = (user: User) => user && user.type === 'logged';

const RouteWithAuth = ({
  children,
  redirectOnLoggedIn,
  redirectOnLoggedOut,
  passesCustomAccessCriteria = defaultAccessCriteria,
}: Props) => {
  const { user } = useAuth();
  const router = useRouter();

  if (passesCustomAccessCriteria(user)) {
    if (redirectOnLoggedIn) {
      router.replace(redirectOnLoggedIn);
      return <FullscreenSpinner />;
    }
    return <>{children}</>;
  }

  if (user.type === 'logged') {
    router.replace('/no-access');
    return <FullscreenSpinner />;
  }

  if (user.type !== 'unspecified') {
    if (redirectOnLoggedOut) {
      router.replace(redirectOnLoggedOut);
      return <FullscreenSpinner />;
    }
    return <>{children}</>;
  }

  return <FullscreenSpinner />;
};
export default RouteWithAuth;
