import { FieldEnums } from './types';
import { ValidationRule, validationRules } from 'src/validation';
import emailValidator from 'email-validator';

const emailRules: ValidationRule[] = [
  {
    rule: validationRules.required,
    errorMessage: 'Please enter an email address',
  },
  {
    rule: (v) => emailValidator.validate(v as string),
    errorMessage: 'Please enter a valid email',
  },
];

const passwordRules: ValidationRule[] = [
  {
    rule: validationRules.required,
    errorMessage: 'Please enter a password',
  },
];

export const passwordLoginRules = {
  [FieldEnums.email]: emailRules,
  [FieldEnums.password]: passwordRules,
};

export const emailFieldRules = {
  [FieldEnums.email]: emailRules,
};
