import { useState } from 'react';
import { ValidationRule, validate, validateAll } from 'src/validation';

export const clearValues = <T extends Record<string, string>>(v: T): T =>
  Object.keys(v).reduce((acc, key) => ({ ...acc, [key]: '' }), {}) as T;

export const useForm = <T extends Record<string, any>>(
  defaultState: T,
  onFormSubmit: (v: T) => void,
  rules: Partial<Record<keyof T, ValidationRule[]>>,
) => {
  const [values, setValues] = useState<T>(defaultState);

  const [errors, setErrors] = useState<Partial<T>>(clearValues(defaultState));

  const handleChange = (key: keyof T, newValue: any) => {
    if (errors[key] && !validate(rules)(key as string, newValue, values))
      setErrors({ ...errors, [key]: '' });
    setValues({ ...values, [key]: newValue });
  };

  const handleChangeBatch = (update: Partial<T>) => {
    let newErrors = {};
    Object.keys(update).forEach((key) => {
      if (errors[key] && !validate(rules)(key as string, update[key], values))
        newErrors = { ...newErrors, [key]: '' };
    });
    setErrors(newErrors);
    setValues({ ...values, ...update });
  };

  const handleBlur = (key: keyof T) => () => {
    const validationResult = validate(rules)(key as string, values[key], values);
    if (validationResult) setErrors({ ...errors, [key]: validationResult.errorMessage });
    else if (errors[key]) setErrors({ ...errors, [key]: '' });
  };

  const onSubmit = (e, updatedValues?: T) => {
    e?.preventDefault();
    const submissionValues = updatedValues || values;
    const validationResults = validateAll(rules)(submissionValues);

    if (Object.keys(validationResults).length > 0) {
      const newErrors = Object.keys(validationResults).reduce(
        (res, cur) => ({
          ...res,
          [cur]: (validationResults[cur as keyof typeof validationResults] as ValidationRule)
            .errorMessage,
        }),
        {},
      );
      setErrors(newErrors as Partial<T>);
    } else {
      onFormSubmit(submissionValues);
    }
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleChange,
    handleChangeBatch,
    handleBlur,
    onSubmit,
  };
};
