/* eslint-disable @next/next/no-img-element */
import { Card, IconList } from 'src/components';
import { IconProps } from 'src/icons/types';

type Props = {
  left: {
    title: string;
    subtitle?: string;
  };
  right?: {
    title: string;
    subtitle: string;
    iconList: { icon: React.ComponentType<IconProps>; description: string | React.ReactNode }[];
    below: React.ReactNode;
  };
  children: React.ReactNode;
};
export const SideFormTemplate = ({ left, right, children }: Props) => {
  return (
    <div className="min-h-full flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-24 xl:px-32">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src="/assets/homeppl-logomark.svg" alt="Homeppl Logo" />
            <h2 className="mt-6 text-4xl text-gray-900 font-serif">{left.title}</h2>
            {left.subtitle && <p className="mt-2 text-lg text-gray-600">{left.subtitle}</p>}
          </div>

          <div className="mt-8">{children}</div>
        </div>
      </div>
      <div className="hidden overflow-hidden lg:flex lg:flex-col relative w-0 flex-1 bg-blue-100  items-center justify-center">
        <img className="absolute top-[-150px] right-[-150px]" src="/assets/top.svg" alt="" />
        <img className="absolute bottom-[-250px] left-[-300px]" src="/assets/bottom.svg" alt="" />

        {right && (
          <div className="z-10">
            <h2 className="text-2xl text-gray-900 font-serif">{right.title}</h2>
            <p className="mt-2 text-lg  mb-6">{right.subtitle}</p>
            <Card className="w-max">
              <IconList iconClassName="stroke-[1.5px]" listItems={right.iconList} />
            </Card>
            <div className="mt-6">{right.below}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideFormTemplate;
